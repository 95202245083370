<template>
  <div>
    <Menu />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
export default {
  name: 'MainLayout',
  components: {
    Menu
  }
}
</script>

<style lang="scss" scoped>

</style>